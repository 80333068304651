import get from 'lodash/get';
import map from 'lodash/map';

import { toastError } from '@/utils';
import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { portalHost } from '../../../const/host.const';

const {
  patchAlert,
  getAlertData,
  getAlertsExport,
  deleteAlertComment,
  getAlertComments,
  postAlertComment,
} = carRental.alert;

export const scopes = {
  alertData: 'alertData',
  alertsCsvData: 'alertsCsvData',
  updateAlert: 'updateAlert',
  comments: 'comments',
};

const alerts = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError(state, payload) {
          toastError.bind(this)(state, payload, [
            scopes.alertData,
            scopes.comments,
          ]);
        },
        clearData(state) {
          state.alertData.data = [];
          state.comments.data = [];
        },
      },
      actions: {
        getAlertData({ rootState, commit }, { alertUuid, data }) {
          return runAsyncFlow(commit, {
            request: getAlertData,
            params: [alertUuid, data, portalHost(rootState)],
            scope: scopes.alertData,
          });
        },
        updateAlert({ rootState, commit }, { alertId, data }) {
          return runAsyncFlow(commit, {
            request: patchAlert,
            params: [alertId, data, portalHost(rootState)],
            scope: scopes.updateAlert,
          });
        },
        getAlertsExport({ rootState, commit }, { filters }) {
          return runAsyncFlow(commit, {
            request: getAlertsExport,
            params: [filters, portalHost(rootState)],
            scope: scopes.alertsCsvData,
          });
        },
        getComments({ rootState, commit }, { alertUuid }) {
          runAsyncFlow(commit, {
            request: getAlertComments,
            params: [alertUuid, portalHost(rootState)],
            scope: scopes.comments,
          });
        },
        postComment({ rootState, commit, dispatch }, { vehicleVin, alertUuid, content }) {
          runAsyncFlow(commit, {
            request: postAlertComment,
            params: [alertUuid, { vin: vehicleVin }, content, portalHost(rootState)],
            scope: scopes.comments,
          }).then(() => dispatch('getComments', { alertUuid }));
        },
        deleteComment({ rootState, commit, dispatch }, {
          vehicleVin,
          alertUuid,
          commentId,
          userId,
        }) {
          runAsyncFlow(commit, {
            request: deleteAlertComment,
            params: [alertUuid, commentId, { vin: vehicleVin, userId }, portalHost(rootState)],
            scope: scopes.comments,
          }).then(() => dispatch('getComments', { alertUuid }));
        },
      },
      getters: {
        alertDetails(state) {
          return get(state.alertData, 'data.details', {});
        },
        alertVehicleData(state) {
          return get(state.alertData, 'data.vehicle', {});
        },
        vehicleMovementData(state) {
          return get(state.alertData, 'data.vehicle.movement', {});
        },
        mapPaths(state) {
          return {
            before: map(get(state.alertData, 'data.details.map.before', []), point => ({
              lat: point.latitude,
              lng: point.longitude,
            })),
            after: map(get(state.alertData, 'data.details.map.after', []), point => ({
              lat: point.latitude,
              lng: point.longitude,
            })),
          };
        },
      },
    }
  )));

export default alerts;
